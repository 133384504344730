import styled from 'styled-components';
import { Button } from '../../../Buttons/Buttons';

import { useMemo, useState, useEffect } from 'react';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { useAudioContext } from '../../../../context/contexts/audio/AudioContext';
import Theme from '../../../../style/theme';

export const ToggleSound = () => {

	const { getFileURL } = useDataContext();
	const {backgroundSource, voSource, audioSources} = useAudioContext();

	const [mute, setMute] = useState<boolean>(false);

	const mutedIcon = useMemo(() => {
		return getFileURL && getFileURL('9802faae-e703-4582-8e11-76b3ec82123f');
	},[]);

	const unmutedIcon = useMemo(() => {
		return getFileURL && getFileURL('41cdaf17-9049-4ce9-930c-5ffd17b319af');
	},[]);

	useEffect(() => {
		if(backgroundSource && voSource && audioSources){
			backgroundSource.muted = mute;
			voSource.muted = mute;
			audioSources.map((audioSource, index) => {
				audioSource.muted = mute;
			});
		}
	},[mute]);

	return (
		<StyledButton iconURL={mute ? mutedIcon : unmutedIcon } onClick={() => setMute(a => !a)}/>
	);
};

// styled components

const StyledButton = styled(Button)`
	flex-shrink:0;
`;