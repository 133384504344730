import { createContext, useContext, useEffect, useState } from 'react';
import Loading from '../../../components/Screens/Loading';
import requests from './requests';
import { ReactNode } from 'react';
import { ChatMessage, ChatMessageTranslation, ChatString, DialogueMessage, DialogueMessageTranslation, directus_auth_token, directus_url, File, HintPackage, HintPackageTranslation, limiter, NPC, NPCTranslation, use_cms,} from '../../../utilities/directus';
import { ConsoleLabel } from '../../../components/Console/components';

const DataContext = createContext<Partial<DataContextProps>>({});

const DataContextProvider = (props: ContextProps) => {
	
	// state indicates the loading state of data
	// 0 === not started loading
	// 1 === started loading and request are being handles
	// 2 === finished loading and data has been acquired succesfully
	// 3 === finished loading, but part/all of the data has not been acquired succesfully.
	const [dataState, setDataState] = useState<number>(0);

	useEffect(() => {
		dataState === 0 && initialize();
	},[dataState]);

	const initialize = () => {
		setDataState(1);
		getData();
	};

	const getData = async() => {

		const rqs: Promise<RequestResponse>[] = [];

		for(let i = 0; i < requests.length; i++){
			await limiter.removeTokens(1);
			rqs.push(requests[i]());
		}

		await Promise.all(rqs).then((responses) => {
			const responsesObject = {};
			responses.map((value) => {
				responsesObject[value.collection] = value.response;
			});
			setData(responsesObject);

		}).catch((error) => {
			setDataState(3);
			console.error(error);

		});
	};

	const [data, setData] = useState<undefined | ResponseData>();

	useEffect(() => {
		if(data){
			setDataState(2);
		}
	},[data]);


	// return an file url based on given string. 
	// The url is built up from different variables which allow for cache breaking the file.
	const getFileURL = (id: string): string  => {

		if(!data || !data.files ) return 'undefined';

		if(!data.files[id]) console.error(`image ${id} data not found`);
		const file = data.files[id];

		if(file !== undefined){
			if(use_cms){
				return `${directus_url}assets/${file.filename_disk}&${file.modified_on}?access_token=${directus_auth_token}`;
			} else {
				return file ? require(`../../../assets/files/${file.filename_disk}`) : 'file not found';
			}
		}

		return '';
	};

	const passedValues = {
		dataState,
		data,
	};

	const passedFunctions = {
		getFileURL,
	};

	return (
		<>	
			<DataContext.Provider value={{...passedValues, ...passedFunctions}}>
				<Loading dataState={dataState}/>
				{ dataState === 2 &&
					<>{props.children}</>
				}
			</DataContext.Provider>
		</>
	);

};

const useDataContext = () => useContext(DataContext);

//types
export type ContextProps = {
    children: ReactNode
}

export type DataContextProps = {

	// values
    dataState: number;
	data: undefined | ResponseData;

	// functions
	getFileURL: (val: string) => string;
}

export type ResponseData = {
	files? : {[key: string] : File},
	HintPackages_translations?: {[key: string] : HintPackageTranslation},
	HintPackages?: {[key: string] : HintPackage}
	ChatMessage?: {[key: string] : ChatMessage}
	ChatMessage_translations?: {[key: string] : ChatMessageTranslation}
	ChatString?: {[key: string] : ChatString}
	NPC?: {[key: string] : NPC}
	NPC_translations?: {[key: string] : NPCTranslation}
	dialogue?: {[key:string]: DialoguePackage}
}

export type RequestResponse = {
	response: any,
	collection: string,
}

export type DialoguePackage = {
	object: DialogueMessage, translation: DialogueMessageTranslation
}


export type FileURL = string | undefined;
export {DataContext, DataContextProvider, useDataContext};
