import { lazy } from 'react';
import { RoomCheck } from '../../RoomCheck';

const roomKey = 'art';

const ArtShell = lazy(() => import('./ArtShell'));
const Theatre = lazy(() => import('./PuzzleElements/artroom_Theatre'));
const Console = lazy(() => import('./PuzzleElements/artConsole'));
const Closet = lazy(() => import('./PuzzleElements/Closet'));


export const Art = () => {

	// render
	return (
		<>
			<RoomCheck roomKey={roomKey}>
				<ArtShell/>
				<Theatre/>
				<Console/>
				<Closet/>
			</RoomCheck>
		</>
	);

};