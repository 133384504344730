import { useEffect } from 'react';
import { Euler } from 'three';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import { useInventoryContext } from '../../../../context/contexts/inventory/InventoryContext';
import { useCameraMovementContext } from './CameraMovement';

const FloorPointer = () => {

	const { point, cameraType } = useCameraMovementContext();
	const { draggingItemID } = useInventoryContext();

	return (
		<> 
			{point && cameraType === 'movement' && draggingItemID === undefined &&
				<mesh position={[point.x, point.y + 0.1, point.z]} rotation={new Euler(Math.PI / -2, 0, 0)}>
					<circleGeometry args={[.2, 30]} />
					<meshStandardMaterial color={'white'} opacity={0.4} />
				</mesh>
			}
		</>

	);
};

export default FloorPointer;