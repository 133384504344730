import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useGlobalContext } from '../global/GlobalContext';
import { usePuzzleContext } from '../puzzle/PuzzleContext';

export const InventoryContext = createContext<Partial<InventoryContextProps>>({});

export const InventoryContextProvider = (props: {children: ReactNode}) => {

	const {setTutorialStep, tutorialStep} = useGlobalContext();
	const {activePuzzle} = usePuzzleContext();

	const [inventory, setInventory] = useState<number[]>([]);
	const [draggingItemID, setDraggingItemID] = useState<number>();
	const [lastDraggingItemID, setLastDraggingItemID] = useState<number>();

	const [lastDragPosition, setLastDragPosition] = useState<{x: number, y:number}>();

	const removeFromInventory = (itemID: number) => {
		setInventory((inventory)=> {
			const newInv = [...inventory];

			for(let i = 0; i < newInv.length; i++){
				if(newInv[i] === itemID){
					newInv.splice(i, 1);
					break;
				}
			}

			return newInv;
		});
	};

	const addToInventory = (itemID: number) => {
		setInventory((inventory) => [...inventory, itemID]);
	};

	useEffect(() => {
		if(setTutorialStep && tutorialStep === 7.1 && inventory[0] === 0){ 
			setTutorialStep(8);
		}
	},[inventory]);

	useEffect(() => {
		if(activePuzzle === 1) return;
		setInventory([]);
	}, [activePuzzle]);

	useEffect(() => {
		if(draggingItemID !== undefined){
			setLastDraggingItemID(draggingItemID);
		}
	},[draggingItemID]);

	const passedValues = {
		inventory,
		draggingItemID,
		lastDragPosition,
		lastDraggingItemID
	};

	const passedFunctions = {
		setDraggingItemID,
		setLastDragPosition,
		addToInventory,
		removeFromInventory,
	};

	return(
		<InventoryContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</InventoryContext.Provider>  
	);
};

export const useInventoryContext = () => useContext(InventoryContext);

// types

type InventoryContextProps = {
    
	inventory: number[];

	draggingItemID: number;
	lastDraggingItemID: number;
	setDraggingItemID: (id: number | undefined) => void,

	lastDragPosition: {x: number, y:number},
	setLastDragPosition: (postion: {x: number, y:number}) => void

	removeFromInventory: (itemID: number) => void,
	addToInventory: (itemID: number) => void,

}
