import { useState } from 'react';
import { ConsoleLabel, ConsoleRow } from '.';
import { usePuzzleContext } from '../../../context/contexts/puzzle/PuzzleContext';

export const PuzzleData = () => {

	const {puzzleData, changePuzzleData, activePuzzle, setActivePuzzle} = usePuzzleContext();

	const [selectValue, setSelectValue] = useState<number>(-1);
	
	return (
		<>
			<ConsoleRow>
				<ConsoleLabel>Active puzzle</ConsoleLabel>
				<select onChange={(e) => setActivePuzzle && setActivePuzzle(Number(e.target.value))} value={activePuzzle}>
					{puzzleData && puzzleData.map((puzzle, index) => (
						<option key={`${index}-activepuzzle`} label={index.toString()} value={index}/>))
					}
				</select>
			</ConsoleRow>
			<ConsoleRow>
				<ConsoleLabel>Puzzle data</ConsoleLabel>
				<select onChange={(e) => setSelectValue(Number(e.target.value))} value={selectValue}>
					<option label={'none'} value={-1}/>
					{puzzleData && puzzleData.map((puzzle, index) => (
						<option key={`${index}-puzzledata-console-puzzleselect`} label={`puzzle-${index}`} value={index}/>))
					}
				</select>
			</ConsoleRow>
			{selectValue !== -1 && 
				<ConsoleRow>
					{puzzleData && puzzleData[selectValue].checks.map((check, index) => (
						<input 
							key={`${index}-puzzledata-console-check`} 
							type={'checkbox'} 
							checked={puzzleData[selectValue].checks[index] === 1 ? true : false}
							onChange={(e) => {
								changePuzzleData && changePuzzleData(selectValue,index,e.target.checked ? 1 : 0);
							}}
						/>
					))}
					<button onClick={() => changePuzzleData && changePuzzleData(2,7,3)}>Luik</button>
				</ConsoleRow>
			}
		</>
	);
};