import styled from 'styled-components';
import { Button } from '../Buttons/Buttons';

import Theme from '../../style/theme';
import { Backdrop } from './components/Container';
import { keyframes } from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { useMemo } from 'react';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../context/contexts/text/TextContext';

const Introduction = () => {

	const { getFileURL } = useDataContext();
	const { setGameState } = useGlobalContext();
	const {t} = useTextContext();
	
	const LogoImg = useMemo(() => {
		return getFileURL && getFileURL('491ee63e-44b8-4952-bbbb-f0c62a33cc95');
	},[]);
	const ArcadisLogo = useMemo(() => {
		return getFileURL && getFileURL('b4d23e4d-4a08-4839-8a6c-9ccf7bade46d');
	},[]);
	const VattenfallLogo = useMemo(() => {
		return getFileURL && getFileURL('fc55341c-abd9-434d-8b0c-f532a9c19a38');
	},[]);
	const GasunieLogo = useMemo(() => {
		return getFileURL && getFileURL('a79b06b6-fc16-4440-86fa-7d6e29705f9e');
	},[]);
	
	return (
		<Backdrop>
			<Container>
				<Logo imageURL={LogoImg}/>
				<Text>
					<div>
						{t('introduction.introtext.part1')}
						<br/><br/>
						{t('introduction.introtext.part2')}
					</div>
				</Text>
				<BottomRow>
					<LogoArcadis imageURL={ArcadisLogo}/>
					<LogoGasunie imageURL={GasunieLogo}/> 
					<LogoVattenfall imageURL={VattenfallLogo}/>
					<Button style={{marginLeft: 'auto'}} onClick={()=> setGameState && setGameState(2)}>{t('introduction.nextbutton')}</Button>
				</BottomRow>
			</Container>
		</Backdrop>
	);
};

// styled components

const fadeIn = keyframes`

	from {
		opacity: 0;
		& > * {
			opacity: 0;
		}
	}

	to {
		opacity: 1;
		& > * {
			opacity: 0;
		}
	}

`;

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0) 100%);

	animation: ${fadeIn} 1s forwards;

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl}px;

	}
`;

const BottomRow = styled.div`
	margin-top: auto;
	display: flex;
	width: 100%;
	gap: 20px;

	align-items: center;
`;

// #region logos

const Logo = styled.div<ImageDiv>`
	background-image: url(${p => p.imageURL});
	background-size: contain;
	background-repeat: no-repeat;
	aspect-ratio: 1 / 2.594;

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		margin-bottom: ${Theme.responsive.whitespace.xs}px;
		height: 20vw;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		margin-bottom: ${Theme.responsive.whitespace.sm}px;
		height: 100px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-bottom: ${Theme.responsive.whitespace.lg}px;
		height: 150px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		margin-bottom: ${Theme.responsive.whitespace.xl}px;
		height: 170px;
	}
`;

const SmallLogo = styled.div<ImageDiv>`
	background-image: url(${p => p.imageURL});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	height: 30px;
	aspect-ratio: 1 / 0.219; 
`;

const LogoVattenfall = styled(SmallLogo)`
`;

const LogoGasunie = styled(SmallLogo)`
`;

const LogoArcadis = styled(SmallLogo)`
`;

//#endregion

const Text = styled.div`
	position: relative;
	overflow-y: auto;
	width: 100%; // change for different devices
	color: ${Theme.colors.neutralLightest};
	text-align: left;

	& > div {
		width: 50%;
	}

	margin-bottom: 20px;

`;

// types

type ImageDiv = {
	imageURL: string
}


export default Introduction;
