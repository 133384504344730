import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { usePuzzleContext } from '../../context/contexts/puzzle/PuzzleContext';

const TutorialManager = () => {

	const {setActiveHint, tutorialStep, setTutorialStep, setActiveDialogueMessage, activeDialogueMessage, setActiveTutorialMessage,setCameraAction, activeScene} = useGlobalContext();
	const {puzzleData, changePuzzleData, activePuzzle, setActivePuzzle} = usePuzzleContext();

	const [tutorialIsActive, setTutorialIsActive] = useState<boolean>(true);
 
	useEffect(() => {
		if(activePuzzle === 0){
			setTutorialStep && setTutorialStep(1);
			changePuzzleData && changePuzzleData(0,2,0);
			changePuzzleData && changePuzzleData(0,4,0);
			changePuzzleData && changePuzzleData(0,1,0);
			changePuzzleData && changePuzzleData(0,3,0);
			changePuzzleData && changePuzzleData(0,0,0);
		} else {
			setActiveTutorialMessage && setActiveTutorialMessage('');
			setTutorialStep && setTutorialStep(10);
			setTutorialIsActive(false);
			changePuzzleData && changePuzzleData(0,2,1);
			changePuzzleData && changePuzzleData(0,4,1);
			changePuzzleData && changePuzzleData(0,3,1);
			changePuzzleData && changePuzzleData(0,0,1);
		}
	},[activePuzzle]);

	useEffect(() => {
		if(activeDialogueMessage === '' && tutorialStep === 2){
			setTutorialStep && setTutorialStep(3);
		}
	},[activeDialogueMessage]);



	useEffect(() => {
		if(!tutorialIsActive) return;
		switch(tutorialStep){
		case 1 :
			//tell the player to walk around:
			setActiveTutorialMessage && setActiveTutorialMessage('tutorial.message-1');
			break;
		case 2 :
			//if player has walked around start dialogue chat:
			setActiveDialogueMessage && setActiveDialogueMessage(activeScene === 1 ? 'tutorial.welkom.modi-1' : 'tutorial.welkom.sandra-1');
			setActiveTutorialMessage && setActiveTutorialMessage('');
			break;
		case 3 :
			//after chat has been closed set hint:			
			//tell player to use the eyes within the hint
			changePuzzleData && changePuzzleData(0,2,1);
			setActiveHint && setActiveHint(4);
			break;
		case 3.1 :
			//wait to click on eys
			changePuzzleData && changePuzzleData(0,3,1);
			changePuzzleData && changePuzzleData(0,0,1);
			break;
		case 4 :
			//clicked on eyes
			setActiveTutorialMessage && setActiveTutorialMessage('tutorial.message-6');
			break;
		case 5 :
			//zoom in on case and tell player to open the case:
			setActiveTutorialMessage && setActiveTutorialMessage('tutorial.message-2');
			break;
		case 6 :
			//tell player to clcik meter:
			setActiveTutorialMessage && setActiveTutorialMessage('tutorial.message-3');
			break;
		case 7 :
			changePuzzleData && changePuzzleData(0,4,1);
			//player can click buttons, click on frame puts it in inventory:
			setActiveTutorialMessage && setActiveTutorialMessage('tutorial.message-4');
			break;
		case 7.1 :
			//zoomed in on meter, wait to click frame
			break;
		case 8 :
			//player puts meter in inventory, after that tell player to go back to movement:
			setActiveTutorialMessage && setActiveTutorialMessage('tutorial.message-5');
			setCameraAction && setCameraAction({type: 'back'});

			break;
		case 9 :
			//player is done back to movement and done with the tutorial
			setTutorialIsActive(false);
			//start first dialogue of the game
			setActiveTutorialMessage && setActiveTutorialMessage('');
			setActivePuzzle && setActivePuzzle(1);
			break;
			//set tutorial to false when done
		}
	},[tutorialStep]);
	

	return (
		<>
		</>
	);
};

export default TutorialManager;


