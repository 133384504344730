import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { Art } from './rooms/Art/Art';
import { Hal } from './rooms/Hal/Hal';
import { Jacuzzi } from './rooms/Jacuzzi/Jacuzzi';
import { Kelder } from './rooms/Kelder/Kelder';
import { Keuken } from './rooms/Keuken/Keuken';
import { Overloop } from './rooms/Overloop/Overloop';
import { Woonkamer } from './rooms/Woonkamer/Woonkamer';
import { Zolder } from './rooms/Zolder/Zolder';

const SceneManager = () => {

	const {activatedRooms} = useGlobalContext();
	
	return(
		<>
			{ activatedRooms &&
			<>
				<Hal/>
				<Overloop/>
				<Keuken/>
				<Woonkamer/>
				<Jacuzzi/>
				<Zolder/>
				<Art/>
				<Kelder/>
			</>
			}
		</>
	);
};

export default SceneManager;