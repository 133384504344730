import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../context/contexts/text/TextContext';
import Theme from '../../style/theme';
import { Backdrop } from './components/Container';
import Firework from './components/Fireworks';

const EndScreen = () => {

	const {t} = useTextContext();
	const {playTimeSeconds, playTimeMinutes} = useGlobalContext();

	return (
		<Backdrop>
			<Container>
				<h1>{t('end.title')}</h1>
				<p>{t('end.text')}</p>
				<TimeParagraph>Eind Tijd: {playTimeMinutes?.toString().padStart(2, '0')+':'+playTimeSeconds?.toString().padStart(2, '0')}</TimeParagraph>
				<Firework/>
			</Container>
		</Backdrop>
	);
};

// styled components

const fadeIn = keyframes`

	from {
		opacity: 0;
		& > * {
			opacity: 0;
		}
	}

	to {
		opacity: 1;
		& > * {
			opacity: 0;
		}
	}

`;

const TimeParagraph = styled.p`
	color: ${Theme.colors.primary};

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		font-size: 28px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		font-size: 36px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		font-size: 40px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		font-size: 56px;
	}
`;

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0) 100%);

	animation: ${fadeIn} 1s forwards;
	text-align: left;

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl}px;

	}

	& > h1 {
		margin: 0;
	}
	color: ${Theme.colors.neutralLightest};

`;

export default EndScreen;