import { ReactNode } from 'react';
import { AudioContextProvider } from './contexts/audio/AudioContext';
import { DataContextProvider } from './contexts/data/DataContext';

import { GlobalContextProvider } from './contexts/global/GlobalContext';
import { InventoryContextProvider } from './contexts/inventory/InventoryContext';
import { PuzzleContextProvider } from './contexts/puzzle/PuzzleContext';
import { TextContextProvider } from './contexts/text/TextContext';

const ContextProvider = (props:{children: ReactNode}) => {
	return (	
		<TextContextProvider>
			<AudioContextProvider>
				<DataContextProvider>
					<GlobalContextProvider>
						<PuzzleContextProvider>
							<InventoryContextProvider>
								{props.children}					
							</InventoryContextProvider>
						</PuzzleContextProvider>	
					</GlobalContextProvider>
				</DataContextProvider>
			</AudioContextProvider>
		</TextContextProvider>
	);
};

export default ContextProvider;