import { useFrame, useThree } from '@react-three/fiber';
import { Initializable } from 'postprocessing';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Matrix4, Quaternion, Vector3 } from 'three';
import { Direction, useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import { useThreeContext } from '../../../../context/contexts/three/ThreeContext';

const Context = createContext<Partial<CameraMovementContextProps>>({});

//const clock = new Clock();

const CameraMovementContext = (props: {children?: ReactNode}) => {

	const {cameraAction, setCameraAction, setCursorState, tutorialStep, setTutorialStep} = useGlobalContext();
	const globalContext = useGlobalContext();

	const {camera} = useThree();
	const {controlsEnabled, setControlsEnabled, targetPosition, setTargetPosition, setHorizontalRestriction, setVerticalRestriction, horizontalRestriction, verticalRestriction} = useThreeContext();
	
	const [cameraType, setCameraType] = useState<CameraType>('movement');

	const [inspectingObject, setInspectingObject] = useState<string | undefined>();
	const [inspectionLayer, setInspectionLayer] = useState<number| undefined>();

	useEffect(() => {
		if(!cameraAction) return;

		switch(cameraAction.type){
		case 'back' : 
			back();
			break;
		case 'reset' : 
			if(!cameraAction.parameters?.position) return;
			reset(cameraAction.parameters?.position, cameraAction.parameters?.facing);
			break;
		}

		setCameraAction && setCameraAction(undefined);

	},[cameraAction, setCameraAction]);

	//update the cameraType value of the global context

	useEffect(() => {
		if(!globalContext.setCameraType) return;
		globalContext.setCameraType(cameraType);
		//if the camera is back to movement, clear the inspect history
		if(cameraType === 'movement'){
			setHistory([]);
			//tutorial activity
			if(tutorialStep === 8){
				setTutorialStep && setTutorialStep(9);
			}
		}
	},[cameraType]);


	//#region Camera movement

	const [newPos, setNewPos] = useState<Vector3>(camera.position);
	const [lookAtTarget, setLookAtTarget] = useState<Quaternion | undefined>(undefined);

	// update camera position each frame
	useFrame((state, delta) => {
		updateCameraPosition(delta);
	});

	// lerps camera values to new values
	const updateCameraPosition = (delta: number) => {

		if(!moving) return;

		// If the camera position equals the new position AND the camera rotation equals the new rotation (if available), re-enable controls
		if(camera.position.equals(newPos) && (lookAtTarget ? camera.quaternion.equals(lookAtTarget) : true)){
			setMoving(false);
			if(!controlsEnabled)
				setControlsEnabled && setControlsEnabled(true);
			return;
		}

		// lerp camera rotation towards the new target position
		if(lookAtTarget && !camera.quaternion.equals(lookAtTarget)){
			camera.quaternion.rotateTowards(lookAtTarget, delta * 2);
		}

		// lerp camera towards new position
		if(!camera.position.equals(newPos)){
			camera.position.lerp(newPos, 0.2);
			if(camera.position.distanceTo(newPos) < 0.001){
				camera.position.set(newPos.x, newPos.y, newPos.z);
			}
		}

	};
	
	// Sets a new camera position

	const setCameraPosition = (position: Vector3, target?: Vector3, type?: CameraType ) => {
		if(!camera || camera.position === position || !targetPosition || !setControlsEnabled) return;

		// while new values are being set, the standard orbit controls are disabled. That way they can not interfere with the transition.
		setControlsEnabled(false);

		setNewPos(position);
		setMoving(true);

		// sets cameraType
		if(type && cameraType !== type){
			setCameraType(type);
		}

		// sets controls target 
		if(!target){
			const diff = new Vector3();
			diff.x = getDiff(targetPosition.x, camera.position.x);
			diff.y = getDiff(targetPosition.y, camera.position.y);
			diff.z = getDiff(targetPosition.z, camera.position.z);
			setTargetPosition && setTargetPosition(position.clone().add(diff));
			setLookAtTarget(undefined);
		} else {
			setTargetPosition && setTargetPosition(target);
			const t = new Matrix4();
			t.lookAt(position, target, camera.up);
			setLookAtTarget(new Quaternion().clone().setFromRotationMatrix(t));
		}

		//https://threejs.org/examples/webgl_math_orientation_transform

	};

	const [history, setHistory] = useState<{cameraPosition: Vector3, targetPosition: Vector3, type: CameraType, horizontalRestrictions: number[], verticalRestrictions: number[]}[]>([]);

	const inspect = ({cameraPosition, newTarget, layer, horizontalRestrictions, verticalRestrictions, objectId} : InspectData) => {
		//layer 0: movement history
		//layer 1: first inspect depth history
		//layer 2: second inspect depth history etc
		if(layer === inspectionLayer && cameraType === 'inspection') return; //prevents reinspection

		if(objectId){
			if(inspectingObject && objectId !== inspectingObject){
				return;
			}
			else if(!inspectingObject){
				setInspectingObject(objectId);
			}
		}

		if(moving || !camera || !targetPosition) return;
		const newArr = [...history];
		newArr[layer] = {
			cameraPosition: new Vector3().copy(camera.position), 
			targetPosition: new Vector3().copy(targetPosition) ,
			type: cameraType,
			horizontalRestrictions: horizontalRestriction ? horizontalRestriction : [],
			verticalRestrictions: verticalRestriction ? verticalRestriction : [0, Math.PI]

		};
		setHistory([...newArr]);
		setInspectionLayer(layer);		
		setCameraPosition(cameraPosition, newTarget, 'inspection');
		setHorizontalRestriction && setHorizontalRestriction(horizontalRestrictions ? horizontalRestrictions : []);
		setVerticalRestriction && setVerticalRestriction(verticalRestrictions ? verticalRestrictions : [0, Math.PI]);
	};

	const move = (cameraPosition: Vector3) => {
		if(moving) return;
		setCameraPosition(cameraPosition);
	};

	const back = () => {
		if(!history || inspectionLayer === undefined) return;
		const historyItem = history[inspectionLayer];
		if(!historyItem) return;
		setCameraPosition(
			historyItem.cameraPosition, 
			historyItem.targetPosition,
			historyItem.type
		);	


		if(inspectionLayer === 0){
			setInspectingObject(undefined);
		}

		setInspectionLayer(a => a && a-1);
		setHorizontalRestriction && setHorizontalRestriction(historyItem.horizontalRestrictions);
		setVerticalRestriction && setVerticalRestriction(historyItem.verticalRestrictions);
	};

	// reset function to set the camera instantly
	const reset = (position: Vector3, facing: Direction | undefined) => {

		if(position)
			camera.position.set(position.x, position.y, position.z);
		else{
			camera.position.set(0,0,0);
		}

		setTargetPosition && setTargetPosition(new Vector3(
			position.x + (
				facing && facing.includes('x+') ? -0.01 :
					facing && facing.includes('x-') ? 0.01 
						: 0), 
			position.y, 
			position.z + (
				facing && facing.includes('z+') ? -0.01 :
					facing && facing.includes('z-') ? 0.01
						: 0), 
		));
	};

	//#endregion
    
	// point on which the cursor is hovering to move.
	const [point, setPoint] = useState<Vector3 | undefined>();
	const [moving, setMoving] = useState<boolean>(false);

	useEffect(() => {
		setCursorState && setCursorState(moving ? 'moving' : 'pointer');
	},[moving]);

	//#region context values

	const passedFunctions = {
		setPoint,
		setMoving,
		inspect,
		move,
	};

	const passedValues = {
		point,
		moving,
		cameraType,
		inspectingObject,
		inspectionLayer
	};

	//#endregion

	//#region render

	return (
		<Context.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</Context.Provider>
	);

	//#endregion
};

const useCameraMovementContext = () => useContext(Context);

// types

type CameraMovementContextProps = {
    point: Vector3 | undefined;
    setPoint: (val: Vector3 | undefined) => void;

    moving: boolean;
    setMoving: (val:boolean) => void;

	cameraType: CameraType;

	inspect: (InspectData: InspectData) => void;
	move: (cameraPosition: Vector3) => void;
	
	inspectingObject: string | undefined;
	inspectionLayer: number| undefined;
}

export type CameraType = 'movement' | 'inspection';

export type InspectData = {
	cameraPosition: Vector3,
	newTarget: Vector3,
	localPositions?: boolean,
	layer: number,
	horizontalRestrictions?: number[],
	verticalRestrictions?: number[],
	objectId: string,
}

// utilities

const getDiff = (a : number, b: number) => {
	return a - b;
};

// exports

export {useCameraMovementContext};
export default CameraMovementContext;
