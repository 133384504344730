import styled from 'styled-components';
import { Button } from '../../../Buttons/Buttons';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import Theme from '../../../../style/theme';

export const Timer = () => {

	const {playTimeSeconds, playTimeMinutes} = useGlobalContext();

	return (
		<Container>{playTimeMinutes?.toString().padStart(2, '0')+' : '+playTimeSeconds?.toString().padStart(2, '0')}</Container>
	);
};

// styled components

const Container = styled.div`
	padding: 0;
	height: 50px;
	width: 100px;
	flex-shrink:0;
	margin-top: 0!important;
	border-radius: ${Theme.responsive.borderRadius.sm};
	border: 1px solid ${Theme.colors.primary};
	background-color: ${Theme.colors.primaryLighter};
	position: relative;
	pointer-events: none;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	@media (min-width: ${Theme.responsive.media.xs}){
			margin: ${Theme.responsive.whitespace.xs  / 2}px;
			font-size: 1rem;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			margin: ${Theme.responsive.whitespace.sm / 2}px;
			font-size: 1.2rem;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			margin: ${Theme.responsive.whitespace.lg / 2}px;
			font-size: 1.4rem;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			margin: ${Theme.responsive.whitespace.xl / 3}px;
			font-size: 1.4rem;

		}
`;