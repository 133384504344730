import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { css } from 'styled-components';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { usePuzzleContext } from '../../../context/contexts/puzzle/PuzzleContext';

import { useDataContext } from '../../../context/contexts/data/DataContext';
import Theme from '../../../style/theme';
import { Button, ButtonProps } from '../../Buttons/Buttons';
import { slideIn } from './sidebar/Inventory';
import { useAudioContext } from '../../../context/contexts/audio/AudioContext';
import soundlist from '../../../assets/Sounds';

const HintButtons = () => {

	const {setActiveHint, toggleHintTimer, activeHint, tutorialStep, setTutorialStep, setToggleHintTimer} = useGlobalContext();
	const {activeHintPackageId, activePuzzle, puzzleData} = usePuzzleContext();
	const {getFileURL} = useDataContext();

	const [showHints, setShowHints] = useState<boolean>(false);
	const [time, setTime] = useState<number>(0);
	const [hintStatus, setHintStatus] = useState<number[]>([1, 1, 1]); // 1 = hint is unavailable, 2 = hint is available, 3 = hint has been read	
	const [amountOfHints, setAmountOfHints] = useState<0 | 1 | 2 | 3>(3); //change to have less or more hints, max is 3
	const {playSound} = useAudioContext();

	const hintUnread = useMemo<boolean>(() => {

		let bool = false;

		hintStatus.map((value) => {
			if(value === 2) bool = true;
		});

		return bool;
	}, [hintStatus]);

	//#region timer 
	const interval = useRef<any | null>(null);
	const maxTime = 30; //time to wait before next hint, in s

	useEffect(() => {
		//starts and ends timer by changing the boolean in global
		if(toggleHintTimer){
		//start timer, only starts when last hint is not yet available or has been read
			if(hintStatus && hintStatus[2] !==2 && hintStatus[2] !==3 && !interval.current){
				interval.current = setInterval(() => {
					setTime(e => e + 1);
				}, 1000);
			}		
		} else if(!toggleHintTimer){
			//pauses because time usestate keeps intact
			endTimer();
		}
	},[toggleHintTimer]);

	const endTimer = () => {
		clearTimeout(interval.current);
		interval.current = null;
		setToggleHintTimer && setToggleHintTimer(false);
	};

	useEffect(() => { //unlocks new hint after the max time is reached
		if(time === maxTime){
			setNextHintAvailable && setNextHintAvailable();	
			endTimer();
			setTime(0);	
		}
	},[time]);

	//#endregion
	
	const setNextHintAvailable = () => {
		//check if tutorial is active
		if(amountOfHints === 1) return;
		//checks which hint is next with status unavailable en sets it to available
		setHintStatus((a) => {
			const hints = [...a];
			const found = hints.findIndex(element => element === 1);
			hints[found] = 2; 		
			return hints;
		});

	};	

	const openHint = (index) => {	
		//opens the right hint en sets the status to has been read
		setActiveHint && setActiveHint(index+1);
		setHintStatus && setHintStatus((a) => {
			const hints = [...a];
			hints[index] = 3; 		
			return hints;
		});	
		
	};

	useEffect(() => {
		//reset all values when the package changes
		setHintStatus([1,1,1]);
		setActiveHint && setActiveHint(undefined);
		if(activeHintPackageId === 2){
			//tutorial has 1 hint
			setAmountOfHints(1);
		} else {
			setAmountOfHints(3);
		}
	},[activeHintPackageId]);

	useEffect(() => {
		//tutorial activities
		if(activeHint === 4){
			setHintStatus([2,1,1]);
		}
		if(tutorialStep === 3 && activeHint === 1){
			setTutorialStep && setTutorialStep(3.1);
		}
	},[activeHint]);

	useEffect(() => {
		hintStatus.map((val, index) => {
			if(val === 2){
				playSound && playSound(soundlist['hintAvailable01']);
			}
		});
	},[hintStatus]);

	const hintButtonIcon = useMemo(() => {
		return getFileURL && getFileURL('95325765-06e4-4c87-a2cc-d9d39bdcab32');
	},[]);
	
	return( 
		<Container margin={puzzleData && puzzleData[0].checks[4] === 1}>
			{ hintStatus && 
			<>
				<HintButton 
					onClick={() => {setShowHints(e => !e);}} active={hintStatus[0] === 2 || hintStatus[1] === 2 || hintStatus[2] === 2}
					iconURL={hintButtonIcon}
				/>
				<HintsContainer>
					{showHints && [...Array(amountOfHints)].map((x, index) => 
						<Hint key={index} 
							onClick={() => {openHint(index), setShowHints(false);}} 
							status={hintStatus && hintStatus[index]} 
							disabled={hintStatus[index] === 1} 
							// timeData={{time: time, maxTime: activePuzzle === 0 ? 0 : maxTime}}
						>
							{index + 1}
						</Hint>
					)}	
				</HintsContainer>	
				{ hintUnread && <HintNotifier/> }
			</>
			}
		</Container>		
	);
};

//styled components

const Container = styled.div<{margin: boolean}>`
	animation: ${slideIn} 1s forwards;

	position: relative;
	flex-shrink: 0;
	${p => !p.margin && css`
		margin-bottom: auto!important;
	`}
	
	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		margin: ${Theme.responsive.whitespace.xs  / 2}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		margin: ${Theme.responsive.whitespace.sm / 2}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		margin: ${Theme.responsive.whitespace.lg / 2}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		margin: ${Theme.responsive.whitespace.xl / 3}px;
	}
`;

const HintsContainer = styled.div`

	position: absolute;
	left: calc(100% + 10px);
	top: 50%;
	transform: translateY(-50%);

	display: flex;
	flex-direction: row;
	flex-shrink: 0;

	gap: 10px;

`;

const HintButton = styled(Button)<{active: boolean} & ButtonProps>`
	border: none;
	
	${p => !p.active && css`
		background-color: grey;
	`}

`;

const Hint = styled(Button)<{status: number}>`
	padding: .5em;

	/* ${p => p.status === 1 ? css`
		background-color: grey;
		pointer-events: none;` 
		: p.status === 2 ? css`
			background-color: gold;
			cursor: pointer;` 
			: css`
				background-color: lightblue;
				cursor: pointer;
	`} */
`;

const HintNotifier = styled.div`
	position: absolute;
	right:0;
	top:0;
	transform: translate(30%, -30%);
	height: 20px;
	width: 20px;
	border-radius: 100%;
	background-color: ${Theme.colors.primary};
`;


export default HintButtons;