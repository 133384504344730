import { lazy } from 'react';
import { RoomCheck } from '../../RoomCheck';

const roomKey = 'hal';

const HalInterior1 = lazy(() => import('./HalInterior1'));
const HalInterior2 = lazy(() => import('./HalInterior2'));
const HalShell = lazy(() => import('./HalShell'));

const SuitCase = lazy(() => import('./PuzzleElements/Suitcase'));
const Meterkast = lazy(() => import('./PuzzleElements/Meterkast'));
const Voordeur = lazy(() => import('./PuzzleElements/Voordeur'));
const Trapdoor = lazy(() => import('./PuzzleElements/Trapdoor'));
const MeterkastDeur = lazy(() => import('./PuzzleElements/Meterkast_deur'));

export const Hal = () => {

	// render
	return (
		<>
			{/* Modie's Room */}
			<RoomCheck roomKey={roomKey} scene={1}>
				<HalInterior1/>
			</RoomCheck>
			{/* Sandra's Room */}
			<RoomCheck roomKey={roomKey} scene={2}>
				<HalInterior2/>
			</RoomCheck>
			{/* Shell */}
			<RoomCheck roomKey={roomKey}>
				<HalShell/>
				{/* Puzzle Objects */}
				<SuitCase
					position={[-1.8,0.715,-1.47]}
					rotation={[0,Math.PI / 6,0]}
				/>
				<Meterkast
					position={[6.17,0,1.6]}
					rotation={[0,3.1,0]}
				/>
				<MeterkastDeur position={[6.6981,0,0.89974]}/>
				<Voordeur rotation={[0, -Math.PI / 2, 0]} />
				<Trapdoor
					position={[1.5,0,0]}
				/>
			</RoomCheck>
		</>
	);
};