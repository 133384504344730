import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import Theme from '../../../../style/theme';
import Hint from './Hint';
import TutorialMessage from './tutorialmessage';

const timeoutTime = 10000;

const MessageBox = () => {
	const {activeHint, activeTutorialMessage, tutorialStep, setActiveHint} = useGlobalContext();
	const {getFileURL} = useDataContext();

	const [active, setActive] = useState<boolean>(true);
	const timeout = useRef<any>(null);

	useEffect(() => {
		//3
		if(!activeHint || tutorialStep && tutorialStep < 9) return;

		if(timeout.current) clearTimeout(timeout.current);

		timeout.current = setTimeout(() => {
			setActive(false);
			setActiveHint && setActiveHint(0);
			
		}, timeoutTime);

		setActive(true);
			
	},[activeHint, tutorialStep]);

	return (
		<>
			{ (activeHint && activeHint <= 3|| activeTutorialMessage) &&
				<Container>
					{ active &&
						<Message>
							{/* <Icon imageURL={getFileURL && getFileURL('95325765-06e4-4c87-a2cc-d9d39bdcab32')}/> */}
							{activeHint ? <Hint/> : 
								activeTutorialMessage ? <TutorialMessage/> : 
									<></>}
						</Message>
					}
				</Container>
			}
		</>
	);
};

//styled components

const Container = styled.div`

	position: absolute;
	left:50%;
	top:0;
	width: 100%;
	transform: translateX(-50%);

	pointer-events: none!important;

	& > * {
		pointer-events: all;
	}


	@media (min-width: ${Theme.responsive.media.xs}){
		padding: ${Theme.responsive.whitespace.xs  / 2}px;
		padding-inline: ${(Theme.responsive.whitespace.xs) + 100}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm / 2}px;
		padding-inline: ${(Theme.responsive.whitespace.sm) + 100}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg / 2}px;
		padding-inline: ${(Theme.responsive.whitespace.lg) + 100}px;

	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.lg * 0.666}px;
		padding-inline: ${(Theme.responsive.whitespace.xl * 0.666) + 100}px;
	}

`;

const Icon = styled.div<{imageURL: string}>`

	background-image: url(${p => p.imageURL});
	background-position: center;
	background-size: contain;

	height: 2em;
	aspect-ratio: 1/1;
`;

const Message = styled.div`

	display: flex;
	align-items: center;
	gap: 20px;
	position: relative;
	background-color: rgba(0,0,0,0.75);
	color: ${Theme.colors.neutralLightest};
	padding: 30px;
	border-radius: ${Theme.responsive.borderRadius.sm};
	
	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		margin-left: ${Theme.responsive.whitespace.xs  / 2}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		margin-left: ${Theme.responsive.whitespace.sm / 2}px;

	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-left: ${Theme.responsive.whitespace.lg / 2}px;

	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		margin-left: ${Theme.responsive.whitespace.xl / 3}px;
	}
`;

export default MessageBox;