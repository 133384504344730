import { lazy } from 'react';
import { RoomCheck } from '../../RoomCheck';

const roomKey = 'zolder';

const ZolderRoom = lazy(() => import('./ZolderRoom'));
const ZolderPuzzleElements = lazy(() => import('./PuzzleElements/ZolderPuzzleElements'));


export const Zolder = () => {

	// render
	return (
		<>
			<RoomCheck roomKey={roomKey}>
				<ZolderRoom/>
				<ZolderPuzzleElements/>
			</RoomCheck>
		</>
	);
};