import { lazy } from 'react';
import { RoomCheck } from '../../RoomCheck';

const roomKey = 'jacuzzi';

const JacuzziRoom = lazy(() => import('./JacuzziRoom'));
const JacuzziObject = lazy(() => import('./PuzzleElements/JacuzziObject'));

export const Jacuzzi = () => {

	// render
	return (
		<>
			<RoomCheck roomKey={roomKey}>
				<JacuzziRoom/>
				{/* Puzzle Object */}
				<JacuzziObject position={[0.119,0.1,-1]} scale={1.25}/>
			</RoomCheck>
		</>
	);
};