import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../context/contexts/text/TextContext';
import InventoryConsole from './components/InventoryConsole';
import Scenes from './components/scenes';
import ConsoleGameStateSelect from './components/GameStateSelect';
import HintPackageSelect from './components/HintActions';
import { PuzzleData } from './components/PuzzleData';
import CameraConsole from './components/Camera';
import DialogueConsole from './components/Dialogue';

const tabs: any  = {
	'Inventory': InventoryConsole,
	'game state': ConsoleGameStateSelect,
	'dialogue': DialogueConsole,
	'scenes' : Scenes,
	'hints' : HintPackageSelect,
	'puzzle data' : PuzzleData,
	'camera': CameraConsole
};

const Console = () => {
	const {displayConsole} = useGlobalContext();
	const {t} = useTextContext();

	const [activeInterfaceItem, setActiveInterfaceItem] = useState<string>('scene');
	const [tabLabels, setTabLabels] = useState<string[] | undefined>();

	useEffect(() => {
		if(!tabLabels){
			const labels: string[] = [];
			for (const [key] of Object.entries(tabs)) {
				labels.push(key);
			}
			setTabLabels(labels);
		}
	}, []);

	const setActiveTab = (key: string) => {
		setActiveInterfaceItem(key);
	};

	//const [ActiveTab, setActiveTab] = useState<ReactNode | undefined>();
	const ActiveTab = tabs[activeInterfaceItem];
	// Render Function
	return (
		<> 
			{displayConsole && 
				<ConsoleContainer>
					<ConsoleWrap>
						<TabContainer>
							{ tabs && Object.keys(tabs).map((key, index) => (
								<button style={activeInterfaceItem === key ? {outline: 'solid 2px cyan'} : {}} key={`console-tabs-${index}-${key}`} onClick={() => setActiveTab(key)}>{key}</button>
							))}
						</TabContainer>				
						{ ActiveTab &&
							<ActiveTab/>
						}	
						<Version>{process.env.REACT_APP_VERSION}</Version>
					</ConsoleWrap>
				</ConsoleContainer>
			}
		</>
	);
	
};


//#region styled components

const ConsoleContainer = styled.div`
    z-index: 10;
    position: absolute;
    left:25%;
    top:0;
	height: auto;
    display: flex;
    justify-content: center;
	width:50vw;
	text-align: left;
`;

const ConsoleWrap = styled.div`
    z-index: 5;
    width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    color: white;
    overflow: auto;
    text-align: left;
	padding: 20px;
`;

const TabContainer = styled.div`

	& > button {
		margin-right: 10px;
	}

`;

const Version = styled.div`
	position:absolute;
	right:0;
	top:0;

	color: white;
	margin: 1px;
`;


//#endregion

export default Console;

