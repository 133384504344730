import React, {useState, createContext, useContext, useEffect} from 'react';

const AudioContext = createContext<Partial<AudioContextProps>>({});

// Contant Variables
const maxAudioSources = 50;

const AudioContextProvider = ({ children }: SceneProps) => {

	const [audioSources, setAudioSources] = useState<HTMLAudioElement[]>([new Audio()]);
	const [voSource] = useState<HTMLAudioElement>(new Audio());

	// const [source, setSource] = useState<any>();
	// const [duration, setDuration] = useState<any>();
	// const [interfaceReady, setInterfaceReady] = useState<boolean>(false);
	// const [clientDone, setClientDone] = useState<boolean>(false);

	const [backgroundSource, setBackgroundSource] = useState<HTMLAudioElement>(new Audio());
	const [muted, setMuted] = useState<boolean>(false);
	// const [newSource, setNewSource] = useState<boolean>(false);

	useEffect(() => {
		if(backgroundSource){
			backgroundSource.loop = true;
		}
	}, [backgroundSource]);

	const playBackgroundSound = (state : boolean, sound?: string, volume?: number) =>{
		if(state || sound){
			if(!backgroundSource.paused && backgroundSource.src !== sound){
				fadeBackgroundSound({sound, volume});
			}
			else if(backgroundSource.src !== sound){
				if(sound){
					backgroundSource.src = sound;
				}
				if(volume){
					backgroundSource.volume = volume;
				}
				backgroundSource.play();
			}
		}
		else{
			backgroundSource.pause();
		}
	};
	// Todo make the audiocontext work by fading up as well
	const volumeStep = 0.1;
	const fadeInterval = 100; // ms between steps
	const fadeBackgroundSound = ({sound, volume = 0} : fadeBackgroundSoundProps) => {
		//Fade sound
		const interval = setInterval(() => {
			const targetVolume = sound ? 0 : volume ? volume : 0;
			const change = backgroundSource.volume > volume ? volumeStep * -1 : backgroundSource.volume < volume ? volumeStep * 1 : 0;


			if(diff(backgroundSource.volume + change,  targetVolume) > 0.05){
				backgroundSource.volume = backgroundSource.volume += change;
			}
			else{
				backgroundSource.volume = targetVolume;
			}

			if(backgroundSource.volume === targetVolume && interval){
				if(sound){
					backgroundSource.src = sound;
					backgroundSource.play();
					fadeBackgroundSound({volume: volume ? volume : 1});
				}
				clearInterval(interval);
			}
		}, fadeInterval);
	};

	const diff = (a, b) => a > b ? a - b : b - a;

	const playSound = (sound: string, volume?: number, vo = false) =>{
		let source = audioSources.find(el => el.paused);
		if(vo){
			source = voSource;
		}
		if (!source && audioSources.length <= maxAudioSources){
			source = new Audio();
			audioSources.push(source);
		}
		if (source) {
			source.src = sound;
			if(volume){
				source.volume = volume;
			}
			source.play();
		}

		if(source){
			source.muted = muted;
		}
		return source;
	};

	const stopVo = () => {
		voSource.pause();
	};

	const passedValues = {
		audioSources,
		backgroundSource,
		voSource,
		muted

	};

	const passedFunctions = {
		playSound,
		playBackgroundSound,
		stopVo,
		setMuted,
	};

	return(
		<AudioContext.Provider value={{...passedValues, ...passedFunctions}}>
			{children}
		</AudioContext.Provider>
	);
};

//types

export type AudioContextProps = {
	playBackgroundSound: (state, key?, volume?) => void,
	playSound: (key, volume?) => void,
	stopVo: () => void,
	duration: number,
	setInterfaceReady: (val: boolean) => void,
	clientDone: boolean,
	audioSources :HTMLAudioElement[]
	backgroundSource : HTMLAudioElement
	voSource: HTMLAudioElement
	setMuted: (val: boolean) => void,
	muted: boolean,
}

export interface SceneProps {
	children: JSX.Element,
}

export interface fadeBackgroundSoundProps {
    sound?: string,
    volume?: number,
}


const useAudioContext = () => React.useContext(AudioContext);

export { AudioContextProvider, useAudioContext, AudioContext};
