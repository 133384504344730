import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import soundlist from '../../../assets/Sounds';
import { useAudioContext } from '../audio/AudioContext';
import { useGlobalContext } from '../global/GlobalContext';
import { data, PuzzleData } from './PuzzleData';

export const PuzzleContext = createContext<Partial<PuzzleContextProps>>({});

export const PuzzleContextProvider = (props: {children: ReactNode}) => {

	const {setToggleHintTimer, setTutorialStep, activeScene, setActiveDialogueMessage} = useGlobalContext();
	//#region use states
	const [activePuzzle, setActivePuzzle] = useState<number>(0);
	const {playSound} = useAudioContext();

	const [puzzleData, setPuzzleData] = useState<PuzzleData>(data);
	//#endregion

	//#region functions
	const changePuzzleData = (puzzleId: number, checkId: number, checkValue: number) => {
		setPuzzleData && setPuzzleData(currentPuzzleData => {
			return currentPuzzleData.map((puzzleDataBlock, puzzleDataBlockIndex) => {
				if(puzzleId === puzzleDataBlockIndex){
					return {
						hintPackageId : puzzleDataBlock.hintPackageId,
						checks : puzzleDataBlock.checks.map((check, checkIndex) => {
							if(checkId === checkIndex){
								return checkValue;
							}
							else{
								return check;
							}
						})
					};
				}
				else{
					return puzzleDataBlock;
				}
			});
		});
	};
	//#endregion
	useEffect(() => {
		setToggleHintTimer && setToggleHintTimer(true);
		if(activePuzzle != 0) playSound && playSound(soundlist['puzzleCompleted']);

		switch(activePuzzle){
		case 0:
			setTutorialStep && setTutorialStep(1);
			break;
		case 1:
			setActiveDialogueMessage && setActiveDialogueMessage('tutorial.klaar.speler-1');
			changePuzzleData && changePuzzleData(1,0,1);
			break;
		case 2:
			setActiveDialogueMessage && setActiveDialogueMessage(`puzzel2.binnenkomst.${activeScene === 1 ? 'modi' : 'sandra'}-1`);
			break;
		case 3:
			setActiveDialogueMessage && setActiveDialogueMessage(`puzzel3.binnenkomst.${activeScene === 1 ? 'modi' : 'sandra'}-1`);
			break;
		case 4:
			changePuzzleData && changePuzzleData(4,0,1);
			break;
		}

	},[activePuzzle]);
	
	const activeHintPackageId = useMemo<number>(() => 
		puzzleData[activePuzzle].hintPackageId
	,[activePuzzle]);

	const passedValues = {
		activePuzzle,
		activeHintPackageId,
		puzzleData
	};

	const passedFunctions = {
		setPuzzleData,
		setActivePuzzle,

		changePuzzleData
	};

	return(
		<PuzzleContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</PuzzleContext.Provider>  
	);
};

export const usePuzzleContext = () => useContext(PuzzleContext);

// types

type PuzzleContextProps = {
    
    activePuzzle: number,
	setActivePuzzle: (val: number) => void,
	activeHintPackageId: number,

    puzzleData: PuzzleData,
	setPuzzleData: Dispatch<SetStateAction<PuzzleData>>

	changePuzzleData: (puzzleId: number, checkId: number, checkValue: number) => void

}
