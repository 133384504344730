import styled from 'styled-components';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import EndScreen from '../Screens/EndScreen';
import Introduction from '../Screens/Introduction';
import Selection from '../Screens/Selection';


const GameFlowManager = () => {

	const {gameState} = useGlobalContext();

	return(
		<>
			{ gameState !== 0 && 
				<Container>
					{ gameState === 1 ? <Introduction/> :
						gameState === 2 ? <Selection/>:
						// gameState === 3 ? > <Game/> :
							gameState === 4 ? <EndScreen/> :
								<></> }
				</Container>
			}
		</>
	);
};

// styled components

const Container = styled.div`

	position: absolute;
	left:0;top:0;right:0;bottom:0;
	height: 100%;
	width: 100%;

	text-align: center;
	z-index: 9002;
`;

export default GameFlowManager;